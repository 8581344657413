
function ativarValidacaoPreCadastro(){
    $(function(){
        $("[phone-mask]").mask("(00) 0000-0000", {placeholder: "(__) ____-____"} );
        $("[cellphone-mask]").mask("(00) 00000-0000",{placeholder: "(__) _____-____"} );
        $("[cpf-mask]").mask("000.000.000-00", {reverse: true, placeholder: "___.___.___-__"});
        $("[rg-mask]").mask("AA.AAA.AAA-ZZZ",{
           reverse: true,  translation: {"Z": { pattern:  /[aA-zA0-9]/, optional: true}}
        });

    });
}


