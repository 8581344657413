
var validacao_transferencia = {
    init: function(modal){

        var lista_familiares = [];
        var numero = 0;
        var limpar_familiar = $("#botao_limpar");
        limpar_familiar.css("visibility", "hidden");
        
        $("#enviar").prop("disabled", true);
		$("#enviar").css("opacity", 0.5);
		

		$("#check_concordo").on("click", function(){

			var selected = $(this).is(":checked");
			
			if(selected){
				$("#enviar").prop("disabled", false);
				$("#enviar").css("opacity", 1.0);
				return;
			}

			
			$("#enviar").prop("disabled", true);
			$("#enviar").css("opacity", 0.5);


		});


        $("#formOutros").submit(function(e){

            var selected_renda = parseInt($("#renda").children(":selected").val());
            var familiar = $("#sem_familiar").is(":checked");

            if(selected_renda <= 0){
				e.preventDefault();
				modificaModal($("#modalAviso"), 
                    "Ops Ocorreu Problema!", 
                    "Renda inválida", 
                    "Por favor seleciona uma renda válida para prosseguir, por favor tente novamente",
                    2);
                return;
            }

            if(!familiar && lista_familiares.length == 0){
                e.preventDefault();
				modificaModal($("#modalAviso"), 
                    "Ops Ocorreu Problema!", 
                    "Familiares Incorreto!", 
                    "Por favor adicione pelo menos 1 (um) familiar, se caso não houver marque o campo \"Moro Sozinho\"",
                    2);
                return;
            }

            if(familiar && lista_familiares.length > 0){
                e.preventDefault();
				modificaModal($("#modalAviso"), 
                    "Ops Ocorreu Problema!", 
                    "Familiares Incorreto!", 
                    "Por favor limpe os familiares antes de marcar \"Moro Sozinho\"",
                    2);
                return;
            }



           
        });

        $("#botao_limpar").on("click", function(){
					$("#campo_familiares").html("");
					lista_familiares = [];
					limpar_familiar.css("visibility", "hidden");
					return;
		});

        $("#confirma").on("click", function(){
        


            var num = parseInt($("#numero").val());
            numero = num;

            if(numero <= 0 || numero > 20){
                $("#status_numero").html("<p class=\"text-center\"><strong>Por favor adicione  um numero que seja igual a 1 e menor que 20</strong></p>");
                return;
            }


            if(lista_familiares.length > 0){
                $("#campo_familiares").html("");
                lista_familiares = [];
            }

            for(var i = 0; i < numero;i++){

                var container = $("<div></div>");	
                var interno = $("<div></div>");			
                var field = $("<input/>");
                var label = $("<label/>");

                var id = "familiar-" + (i + 1);

                container.addClass("nomes-familiares");
                interno.addClass("input-familiar");

                label.text("Familiar Nº " + (i + 1));
                label.attr("for", id);
                label.attr("class", "input-titulo");

                field.attr("name", "familiar[]");
                field.attr("id", id);
                field.attr("class", "bolsa-form");
                field.prop("required",true);
                field.attr("placeholder", "Nome Completo");

                interno.append(label);
                interno.append(field);
                container.append(interno);
                lista_familiares.push(container);
            
            }

            $("#campo_familiares").append(lista_familiares);
            
            $("#modalNumero").modal("toggle");
            limpar_familiar.css("visibility", "visible");


    });

    }


    
};
