

function validacaoStatusPrecadastro(url){
    $(function(){
        var $status = $("#status_precadastro");

        $status.change(function(){
            var $elem = $(this);
            var $checked = $elem.is(":checked") ? 1 : 0;
            

            $.post(url, {status: $checked, instituicao: 1 }, function(d){
                console.log(d);
            });

        });

    });
}
