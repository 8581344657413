var cadastro_candidato = {
	processo_id: null,
	instituicao_id: null,
	bolsas: null,
	rendas: null,
	url: null,
	bolsa_id: 0,
	renda_id:0,


	init: function(url, processo_id, instituicao_id){
		var form = $("#formcadastro");
		var that = this;
		var limpar_familiar = $("#botao_limpar");
		limpar_familiar.css("visibility", "hidden");

		that.processo_id = processo_id;
		that.instituicao_id = instituicao_id;

		that.bolsas = $("#bolsas");
		that.rendas = $("#rendas");

		var total_familiares = 0;
		var lista_familiares = [];

	
		$("#enviar").prop("disabled", true);
		$("#enviar").css("opacity", 0.5);
		

		$("#check_concordo").on("click", function(){

			var selected = $(this).is(":checked");
			
			if(selected){
				$("#enviar").prop("disabled", false);
				$("#enviar").css("opacity", 1.0);
				return;
			}

			
			$("#enviar").prop("disabled", true);
			$("#enviar").css("opacity", 0.5);


		});

		$("#botao_limpar").on("click", function(){
					$("#campo_familiares").html("");
					lista_familiares = [];
					limpar_familiar.css("visibility", "hidden");
					return;
		});

		form.submit(function(e){
			
			//alert("FORM ENVIADO");
		

			var selected_renda = $("#rendas").children(":selected").val();
			var selected_bolsa = $("#bolsas").children(":selected").val();
			var familiar = $("#sem_familiar").is(":checked");
			var concordo = $("#check_concordo").is(":checked");


			if(!concordo){
				e.preventDefault();
				modificaModal($("#modalAviso"), 
                    "Ops Ocorreu Problema!", 
                    "Termos e Politicas", 
                    "Os Termos devem ser aceitos para prosseguir com o cadastro, por favor tente novamente",
                    2);
					return;
			}


			if(lista_familiares.length > 0 && familiar){
				e.preventDefault();
				modificaModal($("#modalAviso"), 
                    "Ops Ocorreu Problema!", 
                    "Por Favor Selecione apenas uma opção", 
                    "a opção \"moro sozinho\" não pode estar selecionada se houver familiares cadastrados, por favor tente novamente ",
                    2);
					return;
			}

			if(!familiar && lista_familiares.length == 0){
				e.preventDefault();
				modificaModal($("#modalAviso"), 
                    "Ops Ocorreu Problema!", 
                    "Por Favor Selecione apenas uma opção", 
                    "por favor cadastre pelo menos 1 (um) familiar ou marque o campo \"moro Sozinho\"",
                    2);
					return;
			}
			
			if(selected_renda == 0){
				e.preventDefault();
				modificaModal($("#modalAviso"), 
                    "Ops Ocorreu um Erro", 
                    "Por Favor Selecione uma Renda Correta", 
                    "Você não Selecionou uma renda válida, por favor selecione uma corretamente, e tente novamente",
                    2);
					return;
			}

			if(selected_bolsa == 0){
				e.preventDefault();
				modificaModal($("#modalAviso"), 
                    "Ops Ocorreu um Erro", 
                    "Por Favor Selecione uma Bolsa Correta", 
                    "Você não Selecionou uma bolsa válida, por favor selecione uma corretamente, e tente novamente",
                    2);
					return;
				
			}

		});




		$("#confirma").on("click", function(){
				
	

				var num = parseInt($("#numero").val());
				numero = num;

				if(numero <= 0 || numero > 20){
					$("#status_numero").html("<p class=\"text-center\"><strong>Por favor adicione  um numero que seja igual a 1 e menor que 20</strong></p>");
					return;
				}


				if(lista_familiares.length > 0){
					$("#campo_familiares").html("");
					lista_familiares = [];
				}


	

		

				for(var i = 0; i < numero;i++){

					var container = $("<div></div>");	
					var interno = $("<div></div>");			
					var field = $("<input/>");
					var label = $("<label/>");

					var id = "familiar-" + (i + 1);

					container.addClass("nomes-familiares");
					interno.addClass("input-familiar");

					label.text("Familiar Nº " + (i + 1));
					label.attr("for", id);
					label.attr("class", "input-titulo");

					field.attr("name", "familiar[]");
					field.attr("id", id);
					field.attr("class", "bolsa-form");
					field.prop("required",true);
					field.attr("placeholder", "Nome Completo");

					interno.append(label);
					interno.append(field);
					container.append(interno);
					lista_familiares.push(container);
				
				}

				$("#campo_familiares").append(lista_familiares);
				
				$("#modalNumero").modal("toggle");
				limpar_familiar.css("visibility", "visible");


		});

		that.bolsas.on("change", function(e){
			var id = parseInt($(this).children(":selected").val());
			var frase_extra = "</br>Ter cursado pelo menos a 2ª e 3ª série do ensino médio em escola pública ou com bolsa 100% em escola particular";
			that.bolsa_id = id;
			if(id == 0){
				return;
			}

			$.ajax({

				"url": url + "/bolsa_requisicao/"  + id

			}).done(function(d){
				
				if(!d || d == "") return;

				var texto_vagas = "";
 				
				if(parseInt( d[0].restante) <= 0){
					texto_vagas = "Nenhuma Vaga Disponível";
					
				}else {
					if(d[0].restante == 1){
						texto_vagas = "1 Vaga";
					}else if(d[0].restante > 1 ){
						texto_vagas = d[0].restante + " Vagas";
					}

				}

				$("#bolsainfo").html(d[0].descricao + "</br><p><strong>Número de Vagas Disponíveis: " + texto_vagas + "</strong></br>" +  frase_extra);
			}).fail(function(jqXHR, textStatus, status){
				console.log("error " + textStatus + status);
			});




		});



	}
};
