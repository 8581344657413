
var termos_aceite = {
    "start": function(){
        $(document).ready(function(){
            $("#aceite").on("change", function(e){
                var aceite = $(this);
                var check = aceite.is(":checked"); 
                $("#envia").prop("disabled", !check);
            });
        });
    }
};
