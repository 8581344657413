


var paginaProcesso =  function(url){
    var url = url;
    
    var btprocesso = document.getElementById("form_processo");
    //var btoutros =   document.getElementById("form_outros");
    var check_processo =  document.getElementsByName("sel_processo");
    var processos = document.getElementById("processo");
    var anteriores = document.getElementById("anteriores");
    
    
    var instituicao_id = 0;
    var processo_id = 0;

    document.querySelector("#btprocesso_outros").style.display = "none";
    document.querySelector("#btprocesso_outros").style.visibility = "hidden";


    document.querySelector("#anteriores").style.display = "none";
    document.querySelector("#anteriores").style.visibility = "hidden";


    var tipo_processo_selecionado = 1;  //1 vestibular atual, 2  vestibular antigo

    if(check_processo[0].checked){
        processos.style.display = "block";
        anteriores.style.display = "none";
        tipo_processo_selecionado = 1;
        processo_id = check_processo[0].value;
    }

    if(check_processo[1].checked){
        processos.style.display = "none";
        anteriores.style.display = "block";
        tipo_processo_selecionado = 2;
        processo_id = check_processo[1].value;
    }


    check_processo[0].addEventListener("change", function(e){
        processos.style.display = "block";
        anteriores.style.display = "none";
        tipo_processo_selecionado = 1;
        console.log("Selecionado Normal");
        document.querySelector("#btprocesso_outros").style.display = "none";
        document.querySelector("#btprocesso_outros").style.visibility = "hidden";

        document.querySelector("#btprocesso").style.display = "block";
        document.querySelector("#btprocesso").style.visibility = "";


        document.querySelector("#anteriores").style.display = "none";
        document.querySelector("#anteriores").style.visibility = "hidden";


    });


    check_processo[1].addEventListener("change", function(e){
        processos.style.display = "none";
        anteriores.style.display = "block";
        tipo_processo_selecionado = 2;
        console.log("Selecionado Outros");
        document.querySelector("#btprocesso").style.display = "none";
        document.querySelector("#btprocesso").style.visibility = "hidden";


        document.querySelector("#btprocesso_outros").style.display = "block";
        document.querySelector("#btprocesso_outros").style.visibility = "";


        document.querySelector("#anteriores").style.display = "block";
        document.querySelector("#anteriores").style.visibility = "";
        
       
    });
  
  



    window.addEventListener("beforereload", function(){
        processos.selectedIndex = -1;
        anteriores.selectedIndex = -1;
        processos.selectedIndex = 0;

        

    });

   

    //evento de escolha de processo
    processos.addEventListener("change", function(e){
      
        var index = e.target.selectedIndex;
        var value = e.target[index].value;
        var id = e.target[index].getAttribute("id_instituicao");

        instituicao_id = id;
        processo_id = value;
    });

    //evento de escolha de processos anteriores
    anteriores.addEventListener("change", function(e){
        var index = e.target.selectedIndex;
        var value = e.target[index].value;
        var id = e.target[index].getAttribute("id_instituicao");

        instituicao_id = id;
        processo_id = value;

    });


        //evento envia formulario
    btprocesso.addEventListener("submit", function (e){
        e.preventDefault();


        var processo = processos.selectedIndex;
        var anterior = anteriores.selectedIndex;

        

        switch(tipo_processo_selecionado){
            case 1:
                if(processo <= 0){
                    modificaModal($("#modalAviso"), "Ops!", "Ocorreu um problema!", "Por Favor Selecione um Vestibular Atual Válido!", MODAL_BOOTSTRAP);
                    return;
                }
            break;

            case 2:
                if(anterior <= 0){
                    modificaModal($("#modalAviso"), "Ops!", "Ocorreu um problema!", "Por Favor Selecione um Vestibular Anterior Válido!", MODAL_BOOTSTRAP);
                    return;
                }
            break;
            default:
                modificaModal($("#modalAviso"), "Ops!", "Ocorreu um problema!", "Acesso Inválido, por favor tente novamente!", MODAL_BOOTSTRAP);
                return;
        }

        var url_final = url + "home/index/" + instituicao_id + "/" + processo_id;
        window.location.href = url_final;
    });

};


var processosAnteriores = function(url){
    var url = url;

    var lista = document.getElementById("processosanteriores");
    var bt = document.getElementById("btprocessosanteriores");

    var instituicao_id = 0;
    var processo_id = 0;

    lista.selectedIndex = 0;


    var escolha_processos = function(e) {

        var index = e.target.selectedIndex;
        var value = e.target[index].value;
        var id = e.target[index].getAttribute("id_instituicao");

        instituicao_id = id;
        processo_id = value;
    };

//teste
  bt.addEventListener("click", envia);
  lista.addEventListener("change", escolha_processos);

};
