var MODAL_FOUNDATION = 1;
var MODAL_BOOTSTRAP  = 2;

function modificaModal(elem, titulo, subtitulo, texto, tipo){
    $(function(){




        elem.on("show.bs.modal", function(e){
            elem.find("#titulo_principal").text("");
            elem.find("#titulo").text("");
            elem.find("#texto").text("");
            elem.find("#titulo_principal").text(titulo);
            elem.find("#titulo").text(subtitulo);
            elem.find("#texto").text(texto);
        });
        
        switch(tipo){
          
            case 1:
                elem.foundation("open");
                break;
            default:
            case 2:
                elem.modal("show");
                break;
        }

       
    });
}

